//JAVASCRIPT REQUIRED FOR ENTIRE WEBSITE
let $form, $message, timeoutId;



function captchaUpload(grecaptchaResponse) {
    // Considering jQuery.validate is being used as the validation mechanism
    
    return new Promise(function(resolve, reject) {  
        
        var Name = jQuery("#Name").val();
        var Company = jQuery("#Company").val();
        var Address = jQuery("#Address").val();
        var City = jQuery("#City").val();
        var State_Province =  jQuery('#State_Province').find(":selected").text();
        var Postal_Code = jQuery("#Postal_Code").val();
        var Telephone = jQuery("#Telephone").val();
        var Facsimile = jQuery("#Facsimile").val();
        var Van_Kam_Pro_Number = jQuery("#Van_Kam_Pro_Number").val();
        var Claim_Amount = jQuery("#Claim_Amount").val();
        var Date_Shipped = jQuery("#datepicker").val();
        var Date_Received = jQuery("#datepicker1").val();
        var Message = jQuery("#Message").val();
        var Claim_Type = jQuery('input[name="Claim_Type"]:checked').val()
        var action = jQuery("#action").val();
        var action1 = jQuery("#uploadFormDoc").attr("newData");
        var Email = jQuery("#Email").val();
        var Email_Recipient = jQuery("#Email_Recipient").val();
        var Email_Subject = jQuery("#Email_Subject").val();
        var Email_From = jQuery("#Email_From").val();
        var Send = jQuery("#Send1").val();
        var trapDet = jQuery("#trapDet").val();
        console.log(trapDet);
        if(trapDet == ""){
            jQuery(".wpcf7-spinner_new").css('visibility', 'initial');
            if ($form.valid()) {
                $form.find('#g-recaptcha-response').length && $form.find('#g-recaptcha-response').remove();
                var file_data = jQuery('#attachment').prop('files')[0];
                var file_data1 = jQuery('#attachment1').prop('files')[0];
                var file_data2 = jQuery('#attachment2').prop('files')[0];
                var file_data3 = jQuery('#attachment3').prop('files')[0];
                var file_data4 = jQuery('#attachment4').prop('files')[0];
                var form_data = new FormData();
                form_data.append('file', file_data);
                form_data.append('file1', file_data1);
                form_data.append('file2', file_data2);
                form_data.append('file3', file_data3);
                form_data.append('file4', file_data4);
                form_data.append('Name', Name);
                form_data.append('Company', Company);
                form_data.append('Address', Address);
                form_data.append('City', City);
                form_data.append('State_Province', State_Province);
                form_data.append('Postal_Code', Postal_Code);
                form_data.append('Telephone', Telephone);
                form_data.append('Facsimile', Facsimile);
                form_data.append('Van_Kam_Pro_Number', Van_Kam_Pro_Number);
                form_data.append('Claim_Amount', Claim_Amount);
                form_data.append('Claim_Type', Claim_Type);
                form_data.append('Date_Shipped', Date_Shipped);
                form_data.append('Date_Received', Date_Received);
                form_data.append('Message', Message);
                //form_data.append('Name', Name);
                form_data.append('action', action);
                form_data.append('Email', Email);
                form_data.append('Email_Recipient', Email_Recipient);
                form_data.append('Email_Subject', Email_Subject);
                form_data.append('Email_From', Email_From);
                form_data.append('Send', Send);
                form_data.append('g-recaptcha-response', grecaptchaResponse);
                console.log(form_data);
                jQuery.ajax({
                        url: $form.attr("action"),
                        dataType    : 'text',           // what to expect back from the PHP script, if anything
                        cache       : false,
                        contentType : false,
                        processData : false,
                    
                    
                        //data: "name="+ name + "&email=" + email + "&submit=" + submit + "&recepient=" + recepient + "&subject=" + subject + "&from=" + from + "&filen=" + filen + "&filet=" + filet + "&filex=" + filex + "&g-recaptcha-response=" + grecaptchaResponse
                        
                        data: form_data,
                        type: "POST",
                    })
                    .done(function(response) {
                        // Display response message to the user | add success class to the message wrapper
                        $message.slideUp(400, () => {
                            $message
                                .removeClass("error")
                                .addClass("success")
                                .html(response)
                                .slideDown();
                        });
                        // Hide the message after 7 seconds
                        timeoutId && clearTimeout(timeoutId);
                        timeoutId = setTimeout(function() {
                            $message.slideUp(400, () => {
                                $message
                                    .html("")
                                    .removeClass("success");
                            });
                        }, 7000);
                        // Resetting reCaptcha (v2) if available
                        grecaptcha && grecaptcha.reset();
                        // Uncomment for Submiting Google Analytics

                        // if (response.toLowerCase().indexOf('thank') > -1) {
                        //     gtag('event', 'Form Submission - ' + $form[0].elements[$form[0].elements.length - 1].value, {
                        //         'event_category': 'Form',
                        //         'event_label': $form[0].elements[$form[0].elements.length - 1].value,
                        //         'value': 1
                        //     });
                        // }
                        // Reset the form input fields
                        $form.trigger("reset");
                        resolve();
                    })
                    .fail(function(error) {
                        // Display error message to the user | add error class to the message wrapper
                        console.log(error);
                        $message.slideUp(400, () => {
                            $message
                                .removeClass("success")
                                .addClass("error-send-mail")
                                .text(response ? response : "Oops! An error occured")
                                .slideDown();
                        });
                        reject();
                    });
            } else {
                reject();
                grecaptcha && grecaptcha.reset();
            }
        }else{
            if (window.location.href.indexOf("/portal/") == -1 || $formID == 'createAcc') {
                if ($form.valid()) {
                    console.log('form valid');
                    $form.find('#g-recaptcha-response').length && $form.find('#g-recaptcha-response').remove();

                    jQuery.ajax({
                            type: "POST",
                            url: $form.attr("action"),
                            data: $form.serialize() + "&g-recaptcha-response=" + grecaptchaResponse,
                        })
                        .done(function(response) {
                                        console.log('ajax done');
                            // Display response message to the user | add success class to the message wrapper
                            $message.slideUp(400, () => {
                                $message
                                    .removeClass("error")
                                    .addClass("success")
                                    .html(response)
                                    .slideDown();
                            });
                            // Hide the message after 7 seconds
                            timeoutId && clearTimeout(timeoutId);
                            timeoutId = setTimeout(function() {
                                $message.slideUp(400, () => {
                                    $message
                                        .html("")
                                        .removeClass("success");
                                });
                            }, 7000);
                            // Resetting reCaptcha (v2) if available
                            grecaptcha && grecaptcha.reset();
                            // Uncomment for Submiting Google Analytics

                            // if (response.toLowerCase().indexOf('thank') > -1) {
                            //     gtag('event', 'Form Submission - ' + $form[0].elements[$form[0].elements.length - 1].value, {
                            //         'event_category': 'Form',
                            //         'event_label': $form[0].elements[$form[0].elements.length - 1].value,
                            //         'value': 1
                            //     });
                            // }
                            // Reset the form input fields
                            $form.trigger("reset");
                        })
                        .fail(function(error) {
                                    console.log('ajax failed');
                            // Display error message to the user | add error class to the message wrapper
                            console.log(error);
                            $message.slideUp(400, () => {
                                $message
                                    .removeClass("success")
                                    .addClass("error-send-mail")
                                    .text(response ? response : "Oops! An error occured")
                                    .slideDown();
                            });
                        });
                } else {
                    console.log('form else');
                    grecaptcha && grecaptcha.reset();
                }
            }
        }
    }); //end promise
}
function captchaSubmit(grecaptchaResponse) {
    // Considering jQuery.validate is being used as the validation mechanism
    if ($form.valid()) {
				console.log('form valid');
        $form.find('#g-recaptcha-response').length && $form.find('#g-recaptcha-response').remove();

        jQuery.ajax({
                type: "POST",
                url: $form.attr("action"),
                data: $form.serialize() + "&g-recaptcha-response=" + grecaptchaResponse,
            })
            .done(function(response) {
							console.log('ajax done');
                // Display response message to the user | add success class to the message wrapper
                $message.slideUp(400, () => {
                    $message
                        .removeClass("error")
                        .addClass("success")
                        .html(response)
                        .slideDown();
                });
                // Hide the message after 7 seconds
                timeoutId && clearTimeout(timeoutId);
                timeoutId = setTimeout(function() {
                    $message.slideUp(400, () => {
                        $message
                            .html("")
                            .removeClass("success");
                    });
                }, 7000);
                // Resetting reCaptcha (v2) if available
                grecaptcha && grecaptcha.reset();
                // Uncomment for Submiting Google Analytics

                // if (response.toLowerCase().indexOf('thank') > -1) {
                //     gtag('event', 'Form Submission - ' + $form[0].elements[$form[0].elements.length - 1].value, {
                //         'event_category': 'Form',
                //         'event_label': $form[0].elements[$form[0].elements.length - 1].value,
                //         'value': 1
                //     });
                // }
                // Reset the form input fields
                $form.trigger("reset");
            })
            .fail(function(error) {
						console.log('ajax failed');
                // Display error message to the user | add error class to the message wrapper
                console.log(error);
                $message.slideUp(400, () => {
                    $message
                        .removeClass("success")
                        .addClass("error-send-mail")
                        .text(response ? response : "Oops! An error occured")
                        .slideDown();
                });
            });
    } else {
	    grecaptcha && grecaptcha.reset();
    }
}

jQuery(document).ready(function() {
    jQuery(document).on("submit", 'form', e => {
        $form = jQuery(e.target);
        var $formID = $form.attr('id');
        console.log($formID);
        if($formID == 'uploadFormDoc'){
            console.log("111");
            e.preventDefault();
            $message = $form.find(".form-messages");
            grecaptcha.execute();
            return false;
        }else if($formID == 'paymentMonaris'){
            console.log("2222");
            return true;
        }else{
            console.log("333");
            e.preventDefault();
            if (window.location.href.indexOf("/portal/") == -1 || $formID == 'createAcc') {
                jQuery("#trapDet").val("createAcSet");
                $message = $form.find(".form-messages");
                grecaptcha.execute();
                return false;
            }
        }
        console.log("444");
    });
    jQuery('#uploadFormDoc').submit(function(e) {
        $form = jQuery(e.target);
        var $formID = $form.attr('id');
        console.log($formID);
        e.preventDefault();
        console.log('Handler for .submit() called.');
        $message = $form.find(".form-messages");
        grecaptcha.execute();
        return false;
    });
    /*jQuery(document).on("submit", 'form', e => {
        e.preventDefault();
        $form = jQuery(e.target);
        var $formID = $form.attr('id');
        console.log('form target is ' + $formID);
        if($formID == 'uploadFormDoc'){console.log("here11");
            $message = $form.find(".form-messages");
            grecaptcha.execute();
            return false;
        }else{
            console.log("here222");
            if (window.location.href.indexOf("/portal/") == -1 || $formID == 'createAcc') {
                console.log("here33");
                $message = $form.find(".form-messages");
                grecaptcha.execute();
                return false;
            }
        }
        console.log("here44");
        
    });*/
     
    
        /*jQuery(document).on("submit", 'form', e => {
            e.preventDefault();
            $form = jQuery(e.target);
            var $formID = $form.attr('id');
            console.log('form target is ' + $formID);
            if($formID == 'uploadFormDoc'){console.log("here11");
                $message = $form.find(".form-messages");
                grecaptcha.execute();
                return false;
            }else{
                console.log("here222");
                if($formID == 'paymentMonaris'){
                    console.log("here555");
                    grecaptcha.execute();
                        return false;
                }else{
                    if (window.location.href.indexOf("/portal/") == -1 || $formID == 'createAcc') {
                        console.log("here33");
                        $message = $form.find(".form-messages");
                        grecaptcha.execute();
                        return false;
                    }
                }
            }
            console.log("here44");
            
        });*/

    /*jQuery("#claim-form").submit(function(e) {
        e.preventDefault();    
        $form = jQuery(e.target);

        console.log('click');

        var formData = new FormData(this);
        //formData.append("submitForm", "1");

        for (var pair of formData.entries()) {
            console.log(pair[0]+ ', ' + pair[1]); 
        }
        jQuery.ajax({
            url: '../../en/form/send-mail-test.php',
            type: 'POST',
            data: formData,
            success: function (data) {
                console.log(data);
            },
            cache: false,
            contentType: false,
            processData: false
        });
    });*/
		jQuery('.navbar-toggler').click(function(){
			jQuery('.navbar-toggler-icon').toggleClass('active');
		});
    
    // jQuery.validator.setDefaults({
    //     debug: true,
    //     success: "valid"
    // });


    // jQuery('#contactForm').length > 0 && jQuery('#contactForm').validate();
    // jQuery('#rateRequest').length > 0 && jQuery('#rateRequest').validate();
    // jQuery('#hiringDriver').length > 0 && jQuery('#hiringDriver').validate();
});


//DENSITY CALCULATOR JS
function calculateVolume1() {
		var divisor = 1;
		var unit = document.querySelector('input[name="unit"]:checked').value;
		var length = document.getElementById('length1').value;
		var width = document.getElementById('width1').value;
		var height = document.getElementById('height1').value;
		if (length == '' || width == '' || height == '') {
			document.getElementById('volume1').value = 0;
		} else {
			if (unit == 1) {
				divisor = 1728;
			}
			document.getElementById('volume1').value = parseFloat(Number(length) * Number(width) * Number(height) / divisor).toFixed(2);
			var total = parseFloat(Number(document.getElementById('volume1').value) + Number(document.getElementById('volume2').value) + Number(document.getElementById('volume3').value) + Number(document.getElementById('volume4').value) + Number(document.getElementById('volume5').value)).toFixed(2);
			document.getElementById('volumeTotal').value = total;
		}
}
function calculateDensity1() {
		var weight = document.getElementById('weight1').value;
		var divisor = 1;
		var unit = document.querySelector('input[name="unit"]:checked').value;
		var length = document.getElementById('length1').value;
		var width = document.getElementById('width1').value;
		var height = document.getElementById('height1').value;
		if (length == '' || width == '' || height == '' || weight == '') {
			document.getElementById('volume1').value = 0;
		} else {
			if (unit == 1) {
				divisor = 1728;
			}
			document.getElementById('density1').value = parseFloat(Number(weight) / (Number(length) * Number(width) * Number(height) / divisor)).toFixed(2);
			var total = parseFloat(Number(document.getElementById('density1').value) + Number(document.getElementById('density2').value) + Number(document.getElementById('density3').value) + Number(document.getElementById('density4').value) + Number(document.getElementById('density5').value)).toFixed(2);
			document.getElementById('densityTotal').value = total;
		}
}
function calculateVolume2() {
		var divisor = 1;
		var unit = document.querySelector('input[name="unit"]:checked').value;
		var length = document.getElementById('length2').value;
		var width = document.getElementById('width2').value;
		var height = document.getElementById('height2').value;
		if (length == '' || width == '' || height == '') {
			document.getElementById('volume2').value = 0;
		} else {
			if (unit == 1) {
				divisor = 1728;
			}
			document.getElementById('volume2').value = parseFloat(Number(length) * Number(width) * Number(height) / divisor).toFixed(2);
			var total = parseFloat(Number(document.getElementById('volume1').value) + Number(document.getElementById('volume2').value) + Number(document.getElementById('volume3').value) + Number(document.getElementById('volume4').value) + Number(document.getElementById('volume5').value)).toFixed(2);
			document.getElementById('volumeTotal').value = total;
		}
}
function calculateDensity2() {
		var weight = document.getElementById('weight2').value;
		var divisor = 1;
		var unit = document.querySelector('input[name="unit"]:checked').value;
		var length = document.getElementById('length2').value;
		var width = document.getElementById('width2').value;
		var height = document.getElementById('height2').value;
		if (length == '' || width == '' || height == '' || weight == '') {
			document.getElementById('volume2').value = 0;
		} else {
			if (unit == 1) {
				divisor = 1728;
			}
			document.getElementById('density2').value = parseFloat(Number(weight) / (Number(length) * Number(width) * Number(height) / divisor)).toFixed(2);
			var total = parseFloat(Number(document.getElementById('density1').value) + Number(document.getElementById('density2').value) + Number(document.getElementById('density3').value) + Number(document.getElementById('density4').value) + Number(document.getElementById('density5').value)).toFixed(2);
			document.getElementById('densityTotal').value = total;
		}
}
function calculateVolume3() {
		var divisor = 1;
		var unit = document.querySelector('input[name="unit"]:checked').value;
		var length = document.getElementById('length3').value;
		var width = document.getElementById('width3').value;
		var height = document.getElementById('height3').value;
		if (length == '' || width == '' || height == '') {
			document.getElementById('volume3').value = 0;
		} else {
			if (unit == 1) {
				divisor = 1728;
			}
			document.getElementById('volume3').value = parseFloat(Number(length) * Number(width) * Number(height) / divisor).toFixed(2);
			var total = parseFloat(Number(document.getElementById('volume1').value) + Number(document.getElementById('volume2').value) + Number(document.getElementById('volume3').value) + Number(document.getElementById('volume4').value) + Number(document.getElementById('volume5').value)).toFixed(2);
			document.getElementById('volumeTotal').value = total;
		}
}
function calculateDensity3() {
		var weight = document.getElementById('weight3').value;
		var divisor = 1;
		var unit = document.querySelector('input[name="unit"]:checked').value;
		var length = document.getElementById('length3').value;
		var width = document.getElementById('width3').value;
		var height = document.getElementById('height3').value;
		if (length == '' || width == '' || height == '' || weight == '') {
			document.getElementById('volume3').value = 0;
		} else {
			if (unit == 1) {
				divisor = 1728;
			}
			document.getElementById('density3').value = parseFloat(Number(weight) / (Number(length) * Number(width) * Number(height) / divisor)).toFixed(2);
			var total = parseFloat(Number(document.getElementById('density1').value) + Number(document.getElementById('density2').value) + Number(document.getElementById('density3').value) + Number(document.getElementById('density4').value) + Number(document.getElementById('density5').value)).toFixed(2);
			document.getElementById('densityTotal').value = total;
		}
}
function calculateVolume4() {
		var divisor = 1;
		var unit = document.querySelector('input[name="unit"]:checked').value;
		var length = document.getElementById('length4').value;
		var width = document.getElementById('width4').value;
		var height = document.getElementById('height4').value;
		if (length == '' || width == '' || height == '') {
			document.getElementById('volume4').value = 0;
		} else {
			if (unit == 1) {
				divisor = 1728;
			}
			document.getElementById('volume4').value = parseFloat(Number(length) * Number(width) * Number(height) / divisor).toFixed(2);
			var total = parseFloat(Number(document.getElementById('volume1').value) + Number(document.getElementById('volume2').value) + Number(document.getElementById('volume3').value) + Number(document.getElementById('volume4').value) + Number(document.getElementById('volume5').value)).toFixed(2);
			document.getElementById('volumeTotal').value = total;
		}
}
function calculateDensity4() {
		var weight = document.getElementById('weight4').value;
		var divisor = 1;
		var unit = document.querySelector('input[name="unit"]:checked').value;
		var length = document.getElementById('length4').value;
		var width = document.getElementById('width4').value;
		var height = document.getElementById('height4').value;
		if (length == '' || width == '' || height == '' || weight == '') {
			document.getElementById('volume4').value = 0;
		} else {
			if (unit == 1) {
				divisor = 1728;
			}
			document.getElementById('density4').value = parseFloat(Number(weight) / (Number(length) * Number(width) * Number(height) / divisor)).toFixed(2);
			var total = parseFloat(Number(document.getElementById('density1').value) + Number(document.getElementById('density2').value) + Number(document.getElementById('density3').value) + Number(document.getElementById('density4').value) + Number(document.getElementById('density5').value)).toFixed(2);
			document.getElementById('densityTotal').value = total;
		}
}
function calculateVolume5() {
		var divisor = 1;
		var unit = document.querySelector('input[name="unit"]:checked').value;
		var length = document.getElementById('length5').value;
		var width = document.getElementById('width5').value;
		var height = document.getElementById('height5').value;
		if (length == '' || width == '' || height == '') {
			document.getElementById('volume5').value = 0;
		} else {
			if (unit == 1) {
				divisor = 1728;
			}
			document.getElementById('volume5').value = parseFloat(Number(length) * Number(width) * Number(height) / divisor).toFixed(2);
			var total = parseFloat(Number(document.getElementById('volume1').value) + Number(document.getElementById('volume2').value) + Number(document.getElementById('volume3').value) + Number(document.getElementById('volume4').value) + Number(document.getElementById('volume5').value)).toFixed(2);
			document.getElementById('volumeTotal').value = total;
		}
}
function calculateDensity5() {
		var weight = document.getElementById('weight5').value;
		var divisor = 1;
		var unit = document.querySelector('input[name="unit"]:checked').value;
		var length = document.getElementById('length5').value;
		var width = document.getElementById('width5').value;
		var height = document.getElementById('height5').value;
		if (length == '' || width == '' || height == '' || weight == '') {
			document.getElementById('volume5').value = 0;
		} else {
			if (unit == 1) {
				divisor = 1728;
			}
			document.getElementById('density5').value = parseFloat(Number(weight) / (Number(length) * Number(width) * Number(height) / divisor)).toFixed(2);
			var total = parseFloat(Number(document.getElementById('density1').value) + Number(document.getElementById('density2').value) + Number(document.getElementById('density3').value) + Number(document.getElementById('density4').value) + Number(document.getElementById('density5').value)).toFixed(2);
			document.getElementById('densityTotal').value = total;
		}
}
// Update totals
function reCalculate() {
	calculateVolume1();
	calculateDensity1();
	calculateVolume2();
	calculateDensity2();
	calculateVolume3();
	calculateDensity3();
	calculateVolume4();
	calculateDensity4();
	calculateVolume5();
	calculateDensity5();
}

// Clear fields
function clearForm() {
	document.getElementById('length1').value = '';
	document.getElementById('width1').value = '';
	document.getElementById('height1').value = '';
	document.getElementById('weight1').value = '';
	document.getElementById('volume1').value = 0;
	document.getElementById('density1').value = 0;
	document.getElementById('length2').value = '';
	document.getElementById('width2').value = '';
	document.getElementById('height2').value = '';
	document.getElementById('weight2').value = '';
	document.getElementById('volume2').value = 0;
	document.getElementById('density2').value = 0;
	document.getElementById('length3').value = '';
	document.getElementById('width3').value = '';
	document.getElementById('height3').value = '';
	document.getElementById('weight3').value = '';
	document.getElementById('volume3').value = 0;
	document.getElementById('density3').value = 0;
	document.getElementById('length4').value = '';
	document.getElementById('width4').value = '';
	document.getElementById('height4').value = '';
	document.getElementById('weight4').value = '';
	document.getElementById('volume4').value = 0;
	document.getElementById('density4').value = 0;
	document.getElementById('length5').value = '';
	document.getElementById('width5').value = '';
	document.getElementById('height5').value = '';
	document.getElementById('weight5').value = '';
	document.getElementById('volume5').value = 0;
	document.getElementById('density5').value = 0;
	document.getElementById('volumeTotal').value = 0;
	document.getElementById('densityTotal').value = 0;
}

jQuery.noConflict();jQuery(document).ready(function(){jQuery("#density_calculator").validate({
rules: {
    length1: {
        number: true
    },
    width1: {
        number: true
    },
    height1: {
        number: true
    },
    weight1: {
        number: true
    },
    length2: {
        number: true
    },
    width2: {
        number: true
    },
    height2: {
        number: true
    },
    weight2: {
        number: true
    },
    length3: {
        number: true
    },
    width3: {
        number: true
    },
    height3: {
        number: true
    },
    weight3: {
        number: true
    },
    length4: {
        number: true
    },
    width4: {
        number: true
    },
    height4: {
        number: true
    },
    weight4: {
        number: true
    },
    length5: {
        number: true
    },
    width5: {
        number: true
    },
    height5: {
        number: true
    },
    weight5: {
        number: true
    }
    }		
    });
});
var headerHeight = jQuery(".header-wrapper").outerHeight();
var lastScrollTop = 0;
jQuery(window).resize(function(){
	var headerHeight = jQuery(".header-wrapper").outerHeight();
});
jQuery(window).scroll(function () {
    var st = jQuery(this).scrollTop();
    if (st > lastScrollTop && st > headerHeight) {
      jQuery(".header-wrapper").addClass("header-sticky");
			if (jQuery(window).outerWidth() < 1200) {
				jQuery(".page-wrapper").css('padding-top', headerHeight + "px");
			}
    }
    else if (st < lastScrollTop && st < headerHeight) {
      jQuery(".header-wrapper").removeClass("header-sticky");
			if (jQuery(window).outerWidth() < 1200) {
				jQuery(".page-wrapper").css('padding-top', 0);
			}
    }
    lastScrollTop = st;
});
var mybutton = document.getElementById("myBtn");
var driverbtns = document.getElementById("driver-btns");

// When the user scrolls down 20px from the top of the document, show the button
window.onscroll = function() {
    
    //console.log(lastSegment[6]); 
        scrollFunction();

};

function scrollFunction() {
    const url = window.location.href;
    const lastSegment = url.split("/");
//console.log(lastSegment);
  if (document.body.scrollTop > 2400 || document.documentElement.scrollTop > 2400) {

    mybutton.style.display = "block";
    if(lastSegment[4] == 'why-vankam.php' || lastSegment[4] == 'index.php' || lastSegment[4] == 'current-opportunities' || lastSegment[4] == ''){
        driverbtns.style.display = "none";
    }
 
  } else {
    mybutton.style.display = "none";
    if(lastSegment[4] == 'why-vankam.php' || lastSegment[4] == 'index.php' || lastSegment[4] == 'current-opportunities' || lastSegment[4] == ''){
        driverbtns.style.display = "block";
    }
  }
}

// When the user clicks on the button, scroll to the top of the document
function topFunction() {
  document.body.scrollTop = 0;
  document.documentElement.scrollTop = 0;
}

(function($) {
  jQuery.fn.inputFilter = function(inputFilter) {
    return this.on("input keydown keyup mousedown mouseup select contextmenu drop", function() {
      if (inputFilter(this.value)) {
        this.oldValue = this.value;
        this.oldSelectionStart = this.selectionStart;
        this.oldSelectionEnd = this.selectionEnd;
      } else if (this.hasOwnProperty("oldValue")) {
        this.value = this.oldValue;
        this.setSelectionRange(this.oldSelectionStart, this.oldSelectionEnd);
      } else {
        this.value = "";
      }
    });
  };
}(jQuery));

jQuery(".numberOnly").inputFilter(function(value) {
    return /^-?\d*[.,]?\d{0,2}$/.test(value);
});
